<template>
    <div>
        <nav class="navbar bg-dark fixed">
                <a href="www.artefactual.com">
                    <img src="@/assets/images/logo.png" alt="Artefactual logo" height="50" class="ms-4">
                </a>    
        </nav>
    </div>
</template>
<script>
    export default {
        name:"NavBar"
    }
</script>
<style>
</style>