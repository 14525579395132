import { createWebHistory, createRouter } from "vue-router";
import FallThrough from "@/views/FallThrough.vue"
import SuspendedView from "@/views/SuspendedView"

const routes = [
    { 
        path: "/",
        name: "Redirect",
        component: FallThrough
    },
    { 
        path: "/suspended",
        name: "Suspended",
        component: SuspendedView
    }
];

const router = createRouter({
    history: createWebHistory(), //vue router saves history so previous component can be called 
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
  });

export default router; //so other programs can use it