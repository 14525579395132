<template>
    <div>
        <Nav-Bar/>
        <div class="container mx-auto mt-4">
            <div class="row justify-content-evenly">
                <div class="col-md-6 order-md-2">
                    <h1 class="mt-4 mb-2">
                        Sorry!
                    </h1>
                    <!-- <h4 class="text-muted">{{ lastPath }} has been suspended.</h4> -->
                    <h4 class="text-muted">This page has been suspended.</h4>
                    <p class="text-muted">
                        If you're the owner, please reach out to Artefactual at <a href="mailto:info@artefactual.com">info@artefactual.com</a>.
                    </p>
                </div>
                <div class="col-md-4 order-md-1">
                    <!-- <img src="https://www.artefactual.com/wp-content/uploads/2022/08/ARTYHAT-281x300.png" alt="arty oops" class="float-end d-flex"> -->
                    <img src="@/assets/images/Arty-oops.png" alt="arty oops" width="600">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import NavBar from "@/components/NavBar.vue"

    export default {
        name:"SuspendedView",
        components: {
            NavBar
        },
        data(){
            return {
                lastPath: null
            }
        },
        mounted(){
            this.lastPath= window.location.host

        }
    }
</script>
<style>
</style>